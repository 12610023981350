import React, { useEffect } from "react";
import Header from "./Header";
import "../styles/styles.scss";
const $ = require("jquery");

// function GetQueryString(key) {
//     var queryStringValue = "";
//     var vars = [], hash;
//     var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
//     for(var i = 0; i < hashes.length; i++)
//     {
//         hash = hashes[i].split('=');
//         vars.push(hash[0]);
//         vars[hash[0]] = hash[1];
//     }
//     if(vars[key] != undefined){
//         queryStringValue = decodeURIComponent(vars[key]);
//     }
//     return queryStringValue;
// }

const LayoutCustom = ({ children }) => {
    useEffect(() => {
        // This code runs when the Layout component loads
        var lang = window.sessionStorage.getItem("lang");
        if(lang == null || lang == '' || lang == 'en'){
			$(".app-data-vi").each ( function (){
				$(this).hide();
			});
            $(".app-data-vi-info").each (function (){
                $(this).attr("style", "display: none !important");;
            });
			$(".app-display-none").each ( function (){
				$(this).removeClass("app-display-none");
			});
            $(".app-data-en").each ( function (){
				$(this).show();
			});
            $(".app-data-en-info").each ( function (){
				$(this).show();
			});
            $("body").each (function (){
                $(this).addClass("app-en-lang");
            });
			$("#app-select-lang").val("en");
            $(".lang-custom-select select").val("en");
        }
        else if (lang == 'vi'){
            $(".app-data-en").each ( function (){
				$(this).hide();
			});
            $(".app-data-en-info").each (function (){
                $(this).attr("style", "display: none !important");;
            });
			$(".app-display-none").each ( function (){
				$(this).removeClass("app-display-none");
			});
			$(".app-data-vi").each ( function (){
				$(this).show();
			});
			$(".app-data-vi-info").each ( function (){
				$(this).show();
			});
            $("body").each (function (){
                $(this).addClass("app-vi-lang");
            });
			$("#app-select-lang").val("vi");
            $(".lang-custom-select select").val("vi");
        }
        // Start set value on contact-us page
        // var vname = GetQueryString("name")
        // if(vname != ""){
        //     if(lang == "vi") {
        //         $("#app-contactus-name").each ( function (){
        //             $(this).val("Yêu Cầu Bản Thử Nghiệm");
        //         });
        //     }
        //     else {
        //         $("#app-contactus-name").each ( function (){
        //             $(this).val(vname);
        //         });
        //     }
        // }
        if(lang == "vi"){
            $("#app-contactus-name").each ( function (){
                $(this).attr('placeholder','Tên');
            });
            $("#app-contactus-subject").each ( function (){
                $(this).attr('placeholder','Tiêu Đề');
            });
            $(".app-section-contactus .app-contactus-detail .app-contactus-left .custom-select .select-selected").each ( function (){
                $(this).html("Chủ Đề");
            });
            $("#app-contactus-message").each ( function (){
                $(this).attr('placeholder','Nội Dung');
            });
            $("#app-contact-us-label-validation").each ( function (){
                $(this).html('Để xác thực, xin vui lòng nhập ngày của hôm qua theo định dạng (Tháng/Ngày/Năm). Ví dụ: Hôm qua là ngày 1 tháng 9 năm 2024, bạn sẽ nhập là "09/01/2024".');
            });
            // $("#app-contactus-btn").each ( function (){
            //     $(this).val("Gửi");
            // });
        }
        else {
            $("#app-contactus-name").each ( function (){
                $(this).attr('placeholder','Name');
            });
            $("#app-contactus-subject").each ( function (){
                $(this).attr('placeholder','Subject');
            });
            $(".app-section-contactus .app-contactus-detail .app-contactus-left .custom-select .select-selected").each ( function (){
                $(this).html("I'm interested in…");
            });
            $("#app-contactus-message").each ( function (){
                $(this).attr('placeholder','Message');
            });
            $("#app-contact-us-label-validation").each ( function (){
                $(this).html("Please enter yesterday's date for validation (MM/DD/YYYY)");
            });
            // $("#app-contactus-btn").each ( function (){
            //     $(this).val("Send Message");
            // });
        }
        // End Start set value on contact-us page
        console.log('Layout component has loaded');

        // Your custom script here
        // Example: 
        // document.body.style.backgroundColor = 'lightgray';

        }, []); // Empty array ensures this runs only once when the component mounts
    return (
        <div className="body">
            <Header />
            <div className="main">
                {children}
            </div>
        </div>
    );
};

export default LayoutCustom;
